


function Description(){
    return(
    <div>   
        <p> Here are some topics that you can expect to see here, mostly hardtech
            <br />Things I'm interested in:

            <ul>
                <li>Deep space exploration: Satelite, Spacecraft, etc.</li>
                <li>Thermodynamics and Heat transfer &#40;my fav subject&#41;</li>
                <li>Traditional/plasma wind tunnel &#40;my second fav subject: aerodynamics&#41;</li>
                <li>Manufacturing automation, i love factorio</li>
                <li>Simulation &#40;CFD, CAE, so on&#41;, analysis tools</li>
                <li>AI</li> 
                <li>Also lessons I've learned, both technical and work in general</li>
            </ul>
        </p>
        <p>
                if you have any questions, notice some flaws in my posts, suggesting or just to chat 
                <br />hit me up at <a href="mailto:gunnochaphan@gmail.com">gunnochaphan@gmail.com</a>   
        </p>
        <br></br>
        <h3>Academic experience</h3>
        <p>Aerospace Engineering, 2023 - current 
        <br/><a> Hochschule München, München, Germany</a></p>
        <p>Aerospace Engineering, 2020 - 2023
        <br/><a>Universität Stuttgart, Stuttgart, Germany</a></p>
        <p>Highschool, til 2019
        <br/><a>Suankularb Wittayala School, Bangkok, Thailand</a></p>
    </div>    
    )
}

export default Description;