
import './App.css';
import Header from './components/Header';
import Posts from './components/posts';
import One from './components/pages/One.js';
import TestPostTwo from './components/pages/TestPostTwo';
import Description from './components/Description.js';
import {
  Routes,
  Route,
} from "react-router-dom";
import NavBar from './components/NavBar';
import Three from './components/pages/Three.js';
import Four from './components/pages/Four.js';



function App() {
  return (
    <div className="App">
      <NavBar className="navbar"/>
      <Header />
      <div className='postsList'>
      <Routes>
         <Route path='/' element={<Description/>} />
         <Route path='/Posts' element={<Posts/>} />
         <Route path='/One.js' element={<One/>} />
         <Route path='/TestPostTwo.js' element={<TestPostTwo/>} />
         <Route path='/Three.js' element={<Three/>} />
         <Route path='/Four.js' element={<Four/>} />
       </Routes>
      </div>  
    </div>
  );
}

export default App;
