import { Link } from "react-router-dom"

function Posts (){ 
  

  return(
    <div className="list">
        <p className="listofpost">List of posts</p>      
        <ul className="posts" >
            <li><Link to="/One.js">building an ai-driven innovation researching and discovery terminal</Link></li>
            <li><Link to="/TestPostTwo.js">ai-assisted text-to-diagram hydraulic and pneumatic circuit generator</Link></li>
            <li><Link to="/Three.js">EdisonNews: HackerNews for hardtech enthusiasts</Link></li>
            <li><Link to="/Four.js">the business of building commercial aircraft, an article</Link></li>
        </ul>
    </div>
    )
}

export default Posts