import { Link } from "react-router-dom";



function BackHome (){
    return(
        <p>
            <Link to="/Posts"> &larr; Back to home</Link>
        </p>
    )
}

export default BackHome