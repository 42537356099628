import Markdown from 'markdown-to-jsx';
import React, { useState, useEffect } from 'react';
import BackHome from '../BackHome.js';



function Three() {
    // Change the file name and function name
    const file_name = 'Three.md';
    const [post, setPost] = useState('');

    useEffect(() => {
        import(`../markdown/${file_name}`)
            .then(res => {
                fetch(res.default)
                    .then(res => res.text())
                    .then(res => setPost(res))
                    .catch(err => console.log(err));
            })
            .catch(err => console.log(err));
    });

    return (
        <div className="container">
            <Markdown>
                {post}
            </Markdown>
            <BackHome/>
        </div>
        
    );
}

export default Three;