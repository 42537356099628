

function Description() {
    return(
        <div>
            <h2>Gunn Ochaphan</h2>
            <p>Welcome to my personal blog! 
            <br />I document what I've learned and things I've built here.
            <br />to connect with me: <a href="mailto:gunnochaphan@gmail.com">gunnochaphan@gmail.com</a>
            <br />Based in Munich, Germany
            </p>
            <hr></hr>
        </div>
    )
}

export default Description;